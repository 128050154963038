import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError,
} from "@web3-react/core";

import "react-toastify/dist/ReactToastify.css";
import { injected, walletconnect } from "../connectors";
import { useEagerConnect, useInactiveListener } from "../hooks";
import Web3 from "web3";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import PresaleABI from '../ABI.json'

const connectorsByName = {
  Injected: injected,
  WalletConnect: walletconnect,
};

function SaleCard() {
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;

  const saleAddress = '0xc2B7Eb7e021B2B1bE23C5366Ea83708eCb12955B' // bsc sale address
  const saleAddressEth = '0xcC747a0FA059A9033E83C4bd6FF4BC3720C7829d'

  const [mcap, setMcap] = React.useState('')
  const [currentraise, setCurrentraise] = React.useState('')
  const [selfcontribution, setSelfcontribution] = React.useState('')
  const [percentagesold, setPercentagesold] = React.useState('0')

  React.useEffect(async() => {
    const web3 = new Web3(
      // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
      new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
    );    
    
    const web3eth = new Web3(
      // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
      new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161")
    );    
    const contract = new web3.eth.Contract(PresaleABI, saleAddress);
    const contractEth = new web3eth.eth.Contract(PresaleABI, saleAddressEth);


    // max amount for sell in usd
    let marketCap = await contract.methods.marketcap().call()
    marketCap = await web3.utils.fromWei(marketCap, 'ether') 
    setMcap(numberWithCommas(marketCap))

    // current raise 
    let currentRaise = await contract.methods.currentRaise().call()
    // currentRaise = await web3.utils.fromWei(currentRaise, 'ether')
      currentRaise = 0;


      let currentRaiseEth = await contractEth.methods.currentRaise().call()
    // currentRaiseEth = await web3.utils.fromWei(currentRaiseEth, 'ether');
      currentRaiseEth = 0;

    let totalRaise = parseFloat(currentRaise)+parseFloat(currentRaiseEth)

    // setCurrentraise(parseFloat(currentRaise).toFixed(2).toLocaleString())
    setCurrentraise(numberWithCommas(parseFloat(totalRaise).toFixed(2)))

    const percentageSold = parseFloat(totalRaise).toFixed(2) / marketCap * 100
    setPercentagesold(percentageSold.toFixed(2))

    if(account){
      let contribution = await contract.methods.amounts(account).call()
      contribution = await web3.utils.fromWei(contribution, 'ether') 
      let contributionEth = await contractEth.methods.amounts(account).call()
      contributionEth = await web3.utils.fromWei(contributionEth, 'ether') 
      let totalContribution = parseFloat(contribution)+parseFloat(contributionEth)
      setSelfcontribution(numberWithCommas(parseFloat(totalContribution).toFixed(2)))
    }

  },[account])


  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?=$))/g, ",") + (parts[1] ? "." + parts[1] : "");
}



  return (
    <div>
      <div className="card sale-card">
        <div className="card-body">
            {/*<div className="centered">First CEX launch in April 2023</div>*/}
            <h1 className="customh1">
            Presale Round 1
            </h1>
            <h3 class="customopening">POSTPONED!</h3>


            <div className="coin-logo-container">
                <img className="coin-logo-card"
                     src='/gummysIMG/logo.png' /></div>
            <h4 className="customp">
                1 USDT = 60 $GMY
            </h4>
            {/*<h4 className="customh4">*/}
            {/*    {percentagesold}%*/}
            {/*</h4>*/}

            <div class="progress">
                <div class="progress-bar bg-danger" role="progressbar" style={{width: 1000}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div className="">
              If you have contributed in round 1, you will get your tokens back within 10 days to the same wallet.
            </div>

            <br />
            {/*<h5 style={{textAlign:'center', color:'white', fontWeight:'bold'}}>*/}
            {/*Sold: ${currentraise} / ${mcap} (Round 1)*/}
            {/*</h5>*/}
            <div style={{marginTop:'2rem', border:'1px solid #d0dbff', padding:'10px', textAlign:'center', margin:'12px 42px 0!important'}}>
                <p>
                   The presale is currently closed. Visit our links to know more.
                </p>
            </div>

            <div style={{marginTop:"1rem"}}>
                <div>
                    </div>
                    <button className="btn btn-outline-primary disabled-button"
                            style={{width:'100%', backgroundImage:'linear-gradient(90deg,#d554c9,#603cee 49%,#01d9f9)', color:'white', borderRadius:'30px'}}>
                      {/*<h5>*/}
                      {/*    Opening: <span id="demo" className="countdown"></span>*/}
                      {/*</h5>*/}
                        <a class="white" href="https://gummys.substack.com/" target="_blank">Subscribe to the newsletter now</a>
                    </button>

                {/*     <button className="btn btn-outline-primary"*/}
                {/*                data-toggle="modal"*/}
                {/*                data-target="#exampleModal"*/}
                {/*style={{width:'100%', backgroundImage:'linear-gradient(90deg,#d554c9,#603cee 49%,#01d9f9)', color:'white', borderRadius:'30px'}}>*/}
                {/*    Connect & Buy*/}
                {/*</button>}*/}

            </div>
        </div>
      </div>


    <ToastContainer />
    </div>
  );
}

export default SaleCard;
