import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError,
} from "@web3-react/core";

import "react-toastify/dist/ReactToastify.css";
import { injected, walletconnect } from "../connectors";
import { useEagerConnect, useInactiveListener } from "../hooks";
import Web3 from "web3";

const connectorsByName = {
  Injected: injected,
  WalletConnect: walletconnect,
};

function ConnectModal() {
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;
  const shortedAccount = account;
  const setProvider = (type) => {
    console.log(type);
    window.localStorage.setItem("provider", type);
  };

  const refreshState = () => {
    window.localStorage.setItem("provider", undefined);
    setNetwork("");
    setMessage("");
    setVerified(undefined);
  };

  const disconnect = () => {
    if (connector == connectorsByName["WalletConnect"]) {
      connector.close();
    }

    refreshState();
    deactivate();
  };

  React.useEffect(() => {
    const provider = window.localStorage.getItem("provider");
    console.log("provider is ", provider);
    if (provider) activate(connectorsByName[provider]);
  }, []);

  const [network, setNetwork] = React.useState(undefined);
  const [message, setMessage] = React.useState("");
  const [verified, setVerified] = React.useState();

  const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

  const truncateEthAddress = (address) => {
    if (address !== undefined) {
      const match = address.match(truncateRegex);
      if (!match) return address;
      return `${match[1]}…${match[2]}`;
    }
  };

  const switchChain = async() => {
    if(chainId == 56){
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],
     }) 
    } else {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }],
     })
    }
    
   
  }

  return (
    <div>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{background:'rgba(40, 13, 95, 0.6)'}}
      >
        <div class="modal-dialog" role="document" style={{width:"100%", maxWidth:'432px'}}>
          <div class="modal-content">
            <div class="modal-header" style={{background:'linear-gradient(139.73deg, rgb(229, 253, 255) 0%, rgb(243, 239, 255) 100%)', color:'rgb(40, 13, 95)'}}>
              <h5 class="modal-title" id="exampleModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row">
                {account && active ? (
                  <div>
                    <p style={{ textAlign: "center" }}>
                      Connected as{" "}
                      <span style={{ fontWeight: "bold" }}>{truncateEthAddress(shortedAccount)}</span>{" "}
                    </p>
                    {/* <p style={{ textAlign: "center" }}>
                      on{" "}
                      {chainId == 56
                        ? "Binance Smart Chain"
                        : "Unsupported chain"}
                    </p> */}
                    <p style={{ textAlign: "center", color: "grey" }}>
                      If you want to change your chain, do it manually in your
                      wallet or by using below button.
                    </p>
                    <button className="btn btn-outline-primary"
                    style={{width:'80%',margin:'0 auto', display:'block'}}
                    onClick={switchChain}
                    >
                      Switch to {chainId == 56 ? 'ETH' : 'BSC'}
                    </button>
                    <button
                      className="btn btn-outline-dark"
                      style={{ width: "80%", margin:'0 auto', display:'block', marginTop:"1%" }}
                      onClick={() => {
                        disconnect();
                      }}
                    >
                      Disconnect
                    </button>
                  </div>
                ) : (
                  <div style={{ width: "100%" }}>
                    <div className="row">
                      <div
                        className="col-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          activate(connectorsByName["Injected"]);
                          setProvider("Injected");
                        }}
                      >
                        <img src="/metamask.png" width="100%" style={{maxWidth:"40px", margin:'0 auto', display:'block'}} alt="metamask" />
                        <h4 style={{textAlign:"center"}}>
                        MetaMask
                        </h4>
                      </div>
                      <div
                        className="col-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          activate(connectorsByName["WalletConnect"]);
                          setProvider("WalletConnect");
                        }}
                      >
                        <img
                          src="/walletconnect.png"
                          width="100%"
                          style={{maxWidth:"40px",  margin:'0 auto', display:'block'}}
                          alt="walletconnect"
                        />
                        <h4 style={{textAlign:"center"}}>
                        WalletConnect
                        </h4>
                      </div>
                      {/* <div
                        className="col-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          activate(connectorsByName["Injected"]);
                          setProvider("Injected");
                        }}
                      >
                        <img
                          src="/trustwallet.svg"
                          width="100%"
                          style={{maxWidth:"40px",  margin:'0 auto',display:'block'}}
                          alt="trustwallet"
                        />
                        <h4 style={{textAlign:"center"}}>
                        TrustWallet
                        </h4>
                      </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-dark" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectModal;
