import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'


const POLLING_INTERVAL = 12000;
const RPC_URLS = {
  1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  56: "https://bsc-dataseed1.ninicoin.io"
};

export const injected = new InjectedConnector({
  supportedChainIds: [1, 56]
});

export const walletconnect = new WalletConnectConnector({
    rpc: {
    1: RPC_URLS[1],
    56: RPC_URLS[56] 
    },
    bridge: 'https://bridge.walletconnect.org',
    // qrcodeModalOptions:{
    //   qrcodeModalOptions: {
    //     mobileLinks: [
    //       "trust",
    //     ]
    //   },
    // },
    // pollingInterval: POLLING_INTERVAL,
    qrcode: true,
    
})

