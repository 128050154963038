import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther } from "@ethersproject/units";
import { ToastContainer, toast } from 'react-toastify';
import { ethers } from 'ethers'
import 'react-toastify/dist/ReactToastify.css';
import {
  injected,
  walletconnect,
} from "../connectors";
import Web3 from 'web3'
import Navbar from '../components/Navbar'
import HomepageTop from '../components/HomepageTop'
import '../App.css'
import { useHistory } from "react-router-dom";





function Homepage() {
  


  return (
    <div>
      <Navbar />
      <HomepageTop />

      {/* <h2 style={{textAlign:'center', padding:'15%'}}>Temporarily Suspended</h2> */}
   </div>

  ); 
}

export default Homepage;