import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError,
} from "@web3-react/core";

import "react-toastify/dist/ReactToastify.css";
import { injected, walletconnect } from "../connectors";
import { useEagerConnect, useInactiveListener } from "../hooks";
import Web3 from "web3";
import SaleCard from './SaleCard'

const connectorsByName = {
  Injected: injected,
  WalletConnect: walletconnect,
};

function HomepageTop() {
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;


  return (
    <div className="container">
        <div className="row" style={{marginTop:'10%'}}>
            <div className="col-12 col-md-6">
                <h4 style={{color:'rgb(192, 76, 255)'}}>
                   PLAY, ENGAGE, LEARN, EARN.                 </h4>
                <h2 style={{color:'white'}}>
                The fastest growing community platform in Web3                </h2>
                <p class="only-desktop" style={{color:'white'}}>

                    Over <strong>320k</strong> fans has signed up and Top Gaming studios in the last 30 days. Join us in powering the next decentralised social + entertainment platform for billions of users, powered by the community.
                    <br/>Tokens will be claimable at launch with a vesting period of 6 months
                    <a class="white"
                       href="https://www.gummys.io/tokenomics" target="_blank"> (distribution and locking)</a>.

                 </p>
                {/*<p style={{color:'white'}}>*/}
                {/*    Buy at least 20$ in GMY to make your wallet whitelisted and gain access to our Beta App.</p>*/}
                {/*<div className="visit-website">*/}
                {/*    <h5 style={{color: 'white'}}>Visit <a href="https://www.gummys.io" target="_blank">*/}
                {/*        <span className="website-name">Gummys.io</span>*/}
                {/*    </a> website</h5>*/}
                {/*</div>*/}

                <div className="visit-website">
                    <h4 style={{color:'rgb(192, 76, 255)'}}>
                        Alpha Product:
                    </h4>
                    <a className="pointer" target="_blank"
                       href="https://alpha.gummys.tv">
                        <img className="app-store"
                             src="gummysIMG/testChrome.png"
                        />
                    </a>

                    <a class="pointer"  target="_blank"
                        href="https://play.google.com/store/apps/details?id=tv.gummys.app">
                        <img className="app-store" className="app-store"
                             src="https://assets.website-files.com/61b341515efb8c4a27eb561f/61b8d51bb745d4c2c56fd522_GooglePlay.svg"/>
                    </a>
                <a class="pointer"  target="_blank"
                    href="https://apps.apple.com/ro/app/gummys-tv/id1640719475">
                    <img className="app-store"
                        src="https://assets.website-files.com/61b341515efb8c4a27eb561f/61b8d51b8355ca21b3b8334e_AppStor.svg"/>
                </a>
                </div>


                <div className="social-links">
                    <h4 style={{color:'rgb(192, 76, 255)'}}>
                        Links:
                    </h4>
                    <a href="https://www.gummys.io"
                       target="_blank" className="social-links button-socials">
                        <img src="gummysIMG/website.png"
                             width="50px"
                             loading="lazy" alt="youtube"/>
                    </a>
                    <a href="https://twitter.com/Gummys_io"
                       target="_blank" className="social-links button-socials">
                        <img src="gummysIMG/twitter.png"
                             width="50px"
                             loading="lazy" alt="youtube"/>
                    </a>
                    <a href="https://discord.gg/gummys"
                       target="_blank" className="social-links button-socials">
                        <img src="gummysIMG/discord.png"
                             width="50px"
                             loading="lazy" alt="youtube"/>
                    </a>
                    <a href="https://t.me/gummys_io"
                       target="_blank" className="social-links button-socials">
                        <img src="gummysIMG/telegram.png"
                             width="50px"
                             loading="lazy" alt="youtube"/>
                    </a>
                    <a href="https://gummys.medium.com/"
                       target="_blank" className="social-links button-socials">
                        <img src="gummysIMG/medium.png"
                             width="50px"
                             loading="lazy" alt="youtube"/>
                    </a>
                    <a href="https://coinmarketcap.com/currencies/gummys/"
                       target="_blank" className="social-links button-socials">
                        <img src="gummysIMG/cmc.png"
                             width="50px"
                             loading="lazy" alt="youtube"/>
                    </a>

                    {/*<a*/}
                    {/*href="https://twitter.com/Gummys_io" target="_blank" className="social-links button-socials"><img*/}
                    {/*src="./GUMMYS_files/620fab1890e7551ca670c6ba_twitter 2.svg" loading="lazy" alt="twitter"></a><a*/}
                    {/*href="https://t.me/gummys_io" target="_blank" className="social-links button-socials"><img*/}
                    {/*src="./GUMMYS_files/620fab2aa2e9f062b3c8b2da_telegram.svg" loading="lazy" alt="telegram"></a><a*/}
                    {/*href="https://gummys.medium.com/" target="_blank" className="social-links button-socials"><img*/}
                    {/*src="./GUMMYS_files/620fab4028375f8205d8080d_medium.svg" loading="lazy" alt="medium"></a><a*/}
                    {/*href="https://discord.com/invite/gFDzjjQfDJ?kid=2H9M9J" target="_blank"*/}
                    {/*className="social-links button-socials"><img*/}
                    {/*src="./GUMMYS_files/620fab4e3bba43e9023ed7b8_discord.svg" loading="lazy" alt="discord"></a>*/}
                </div>



                {/*<div className="visit-website">*/}
                {/*    <h5 style={{color: 'white'}}>Visit <a href="https://www.gummys.io" target="_blank">*/}
                {/*        <span className="website-name">Desktop Alpha Version</span>*/}
                {/*    </a></h5>*/}
                {/*</div>*/}

            </div>
            <div className="col-12 col-md-6">
                <SaleCard />
            </div>
        </div>

        <div className="row" style={{marginBottom:'5%' }}>
        <div className="footer__social-links">
            <a href="https://twitter.com/Gummys_io"
               target="_blank" className="button-socials footer-social-single">
                <img src="gummysIMG/twitter.png"
                     width="50px"
                     loading="lazy" alt="youtube"/>
            </a>
            <a href="https://discord.gg/gummys"
               target="_blank" className="footer-social-single button-socials">
                <img src="gummysIMG/discord.png"
                     width="50px"
                     loading="lazy" alt="youtube"/>
            </a>
            <a href="https://t.me/gummys_io"
               target="_blank" className="footer-social-single button-socials">
                <img src="gummysIMG/telegram.png"
                     width="50px"
                     loading="lazy" alt="youtube"/>
            </a>
            <a href="https://gummys.medium.com/"
               target="_blank" className="footer-social-single button-socials">
                <img src="gummysIMG/medium.png"
                     width="50px"
                     loading="lazy" alt="youtube"/>
            </a>
            <a href="https://coinmarketcap.com/currencies/gummys/"
               target="_blank" className="footer-social-single button-socials">
                <img src="gummysIMG/cmc.png"
                     width="50px"
                     loading="lazy" alt="youtube"/>
            </a>


            {/*<a*/}
            {/*href="https://twitter.com/Gummys_io" target="_blank" className="social-links button-socials"><img*/}
            {/*src="./GUMMYS_files/620fab1890e7551ca670c6ba_twitter 2.svg" loading="lazy" alt="twitter"></a><a*/}
            {/*href="https://t.me/gummys_io" target="_blank" className="social-links button-socials"><img*/}
            {/*src="./GUMMYS_files/620fab2aa2e9f062b3c8b2da_telegram.svg" loading="lazy" alt="telegram"></a><a*/}
            {/*href="https://gummys.medium.com/" target="_blank" className="social-links button-socials"><img*/}
            {/*src="./GUMMYS_files/620fab4028375f8205d8080d_medium.svg" loading="lazy" alt="medium"></a><a*/}
            {/*href="https://discord.com/invite/gFDzjjQfDJ?kid=2H9M9J" target="_blank"*/}
            {/*className="social-links button-socials"><img*/}
            {/*src="./GUMMYS_files/620fab4e3bba43e9023ed7b8_discord.svg" loading="lazy" alt="discord"></a>*/}
        </div>
        </div>

        { <div className="row partners-row">
        <div className="col-12">
            <h2 class="partner" style={{textAlign:'center'}}>
                Supported by
            </h2>
        </div>
            <div className="col-3 col-md-2 " class="partners-row">
                <img src="/partner1.png" class="partner-png" style={{width:'8em'}} />
            </div>
        <div className="col-3 col-md-2">
        <img src="/partner2.png" class="partner-png"  style={{width:'8em'}} />
        </div>
        <div className="col-3 col-md-2">
        <img src="/partner3.png" class="partner-png"  style={{width:'7em'}} />
        </div>
        <div className="col-3 col-md-2">
        <img src="/partner4.png" class="partner-png"  style={{width:'8em'}} />
        </div>
        <div className="col-3 col-md-2">
            <img src="/partner5.png" class="partner-png"  style={{width:'8em'}} />
        </div>
        <div className="col-3 col-md-2">
        <img src="/partner6.png" class="partner-png"  style={{width:'8em'}} />
        </div>

    </div> }

        <div className="footer" class="terms-conditions">

            <a style={{padding: "50px"}}
                className="pointer lightgray" href="https://token-sale-agreement.gummys.io/" target="_blank"
                className="footer-link">
               Token Sale Agreement</a>
            <br/>
            {/*<a*/}
            {/*    class="pointer lightgray" href="https://terms-and-conditions.gummys.io/" target="_blank" className="footer-link">*/}
            {/*    Terms and Conditions</a>*/}
        </div>


    </div>






);
}

export default HomepageTop;
