import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError,
} from "@web3-react/core";
import factory_abi from "../utils/abi_factory.json";
import { factoryAddress } from "../utils/addresses";

import "react-toastify/dist/ReactToastify.css";
import { injected, walletconnect } from "../connectors";
import { useEagerConnect, useInactiveListener } from "../hooks";
import Web3 from "web3";

const connectorsByName = {
  Injected: injected,
  WalletConnect: walletconnect,
};

function NavBar() {
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;

  const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

  const truncateEthAddress = (address) => {
    if (address !== undefined) {
      const match = address.match(truncateRegex);
      if (!match) return address;
      return `${match[1]}…${match[2]}`;
    }
  };


  return (
    <nav
      class="navbar sticky-top navbar-expand-lg navbar-dark"
      style={{ background: "#060D25", minHeight: "80px" }}
    >
      <a class="navbar-brand" href="https://www.gummys.io/" style={{ marginLeft: "3%" }}>
        {" "}
        <img
          src="/gummysIMG/logoDark.png"
          style={{ width: "100%", maxWidth: "200px" }}
          alt="logo"
        /> {" "}
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto" style={{ marginRight: "5%" }}>
          {/*<li class="nav-item">*/}
          {/*  <a class="nav-link" style={{ color: "#909fba" }} href="https://www.gummys.io">*/}
          {/*    Home{" "}*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/* <li class="nav-item">
            <a
              class="nav-link"
              style={{ color: "#909fba" }}
              href="https://bscscan.com"
              target="_blank"
              rel="noreferrer"
            >
              Token
            </a>
          </li> */}

          {/* <li
            class="nav-item dropdown"
            style={{ background: "transparent", border: "0px" }}
          >
            <a
              class="nav-link dropdown-toggle"
              href="/profile"
              id="navbarDropdown"
              style={{ color: "white" }}
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Profile
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="/profile">
                Profile
              </a>
              <a
                class="dropdown-item"
                href="https://plugpro.me/help"
                target="_blank"
                rel="noreferrer"
              >
                Help
              </a>
            </div>
          </li> */}


          <li class="nav-item">
            {account && active ? (
              <button
                class="btn btn-dark"
                // data-toggle="modal"
                // data-target="#exampleModal"
                style={{ borderRadius: "5px", width: "100%", marginLeft: "5%" }}
                type="button"
              >
                {" "}
                <img
                  src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/info/logo.png"
                  width="20px"
                />{" "}
                <span> {truncateEthAddress(account)} </span>
              </button>
            ) : (
              <button
                class="btn btn-dark"
                // data-toggle="modal"
                // data-target="#exampleModal"
                style={{ borderRadius: "5px", width: "100%", marginLeft: "5%" }}
                type="button"
              >
                {" "}
                <img
                  src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/info/logo.png"
                  width="20px"
                />{" "}
                <span> Connect (Closed) </span>
              </button>
            )}
          </li>

          {/* <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li> */}
        </ul>

        {/* <div class="d-flex" style={{minWidth:'80px', marginLeft:'5px'}}>
      <a href="/profile">
      <img src={myavatar} style={{border:'1px solid yellowgreen', borderRadius:"25px"}} width="40px" />
      </a>
    </div> */}
      </div>
    </nav>
  );
}

export default NavBar;
