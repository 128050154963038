import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError,
} from "@web3-react/core";

import "react-toastify/dist/ReactToastify.css";
import { injected, walletconnect } from "../connectors";
import { useEagerConnect, useInactiveListener } from "../hooks";
import Web3 from "web3";
import axios from 'axios'
import PresaleABI from '../ABI.json'
import { toast } from 'react-toastify';
import TokenAbi from '../erc20.json'
import {ethers} from 'ethers'
  
const connectorsByName = {
  Injected: injected,
  WalletConnect: walletconnect,
};

function BuyWithUsdtModal() {
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;


  const [amount, setAmount] = React.useState('')
  const [allowance, setAllowance] = React.useState('0')
  const [userbalance, setUserbalance] = React.useState('')

  const saleAddress = '0xcC747a0FA059A9033E83C4bd6FF4BC3720C7829d'
  const saleAddressBNB = '0xc2B7Eb7e021B2B1bE23C5366Ea83708eCb12955B'
  const usdtAddress = '0xdac17f958d2ee523a2206206994597c13d831ec7'


  React.useEffect(async() => {
    if(account){
      const bal = await library.getBalance(account)
      if(bal == '0'){
        toast.error('You do not have enough BNB in your wallet to pay tx fees!')
      }
      const web3 = new Web3(library.provider)
      const contract = new web3.eth.Contract(TokenAbi, usdtAddress);
      const balanceUsdt = await contract.methods.balanceOf(account).call()
      const decimal = await contract.methods.decimals().call()
      const allowance = await contract.methods.allowance(account, saleAddress).call()
      const realBalance = await ethers.utils.formatUnits(balanceUsdt, decimal); // parseUnits

      setUserbalance(realBalance)
      setAllowance(allowance)

    }
  }, [account])


  const buyNow = async() => {
    try{
      const web3 = new Web3(library.provider)
      const web3bsc = new Web3(
        // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
        new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
      );    
      const contract = new web3.eth.Contract(PresaleABI, saleAddress);
      const contractBsc = new web3bsc.eth.Contract(PresaleABI, saleAddressBNB);
      const amountToBuy = await web3.utils.toWei(amount.trim(), 'ether')
      const realBalance = await ethers.utils.parseUnits(amount.trim(), 6); // parseUnits

      // verify amount sold so far and deny request if round target is reached
      const currentRaise = await contract.methods.currentRaise().call()  
      const currentRaiseBsc = await contractBsc.methods.currentRaise().call() 
      const maxRaise = await contract.methods.marketcap().call() 
      const totalRaise = parseFloat(currentRaise)+parseFloat(currentRaiseBsc)

      if((totalRaise+parseFloat(amountToBuy)) <= maxRaise ){
        await contract.methods.buyWithUsdt(realBalance).send({
          from: account      })
        toast('Transaction Confirmed! You may now close this')
      } else {
        toast.error('This transaction would go beyond our maximum selling availability!')
      }




    }catch(err){
      console.log('something went wrong ', err)
      toast.error('Something went wrong!')
    }
  }

  const approve  = async() => {
    try{

        const web3 = new Web3(library.provider)
        const contract = new web3.eth.Contract(TokenAbi, usdtAddress);
        await contract.methods.approve(saleAddress, ethers.constants.MaxUint256).send({
            from: account
        })
        toast('Transaction approved! You may now purchase with USDT')
        setAllowance('10000')

    }catch(err){
        toast.error('Something went wrong')
    }
  }

  return (
    <div>
      <div
        class="modal fade"
        id="BuyWithUsdtModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Buy With USDT
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row" style={{padding:'10px'}}>

                <div className="col-12">
                    <p>
                        Current rate: <span style={{fontWeight:"bold"}}>1 USDT = 60 GMY</span>
                    </p>
                    <p>
                      Your balance: <span style={{fontWeight:'bold'}}>{userbalance} USDT</span>
                    </p>
                </div>
                <div className="col-12">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1" style={{background:'linear-gradient(90deg,#d554c9,#603cee 50%,#01d9f9)',color:'white'}}>USDT</span>
                        </div>
                        <input type="text" class="form-control" onChange={(event) => setAmount(event.target.value)} placeholder="amount of USDT to buy" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div className="col-12 buy-container">
                <img className="coin-logo"
                      src='/gummysIMG/logo.png'/>
                    {amount !== '' ? <> <p style={{textAlign:'center'}}>You will get {parseFloat(amount*60).toFixed(2)} GMY </p></> : null}
                </div>
                <div className="col-12">
                  {chainId == 1 ? <button className="btn btn-outline-primary" onClick={allowance == '0' ? approve : buyNow} style={{width:'100%', backgroundImage:'linear-gradient(90deg,#d554c9,#603cee 49%,#01d9f9)', color:'white', borderRadius:'30px'}}>
                  {allowance == '0' ? 'Approve Spending' : 'Buy NOW'}
                </button>  :  <div class="alert alert-danger" role="alert">
                    You must connect to ETH network to buy with USDT. Switch your network to proceed 
                  </div>}
                
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-dark" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default BuyWithUsdtModal;
